import React, { useEffect, useRef } from "react";
import "./ticker.css";
import {
  YearIcon,
  WeekIcon,
  DiaryIcon,
  InsightsIcon,
  VitIcon,
  PreviousDay,
  QuoteIconV2,
  IkigaiIcon,
} from "../../../components/Icons/Icon";

const icons = [
  <YearIcon key="year" />,
  <WeekIcon key="week" />,
  <DiaryIcon key="diary" />,
  <InsightsIcon key="insights" />,
  <VitIcon key="vit" />,
  <PreviousDay key="previous" />,
  <QuoteIconV2 key="quote" />,
  <IkigaiIcon key="ikigai" />,
  <YearIcon key="year" />,
  <WeekIcon key="week" />,
  <DiaryIcon key="diary" />,
  <InsightsIcon key="insights" />,
  <VitIcon key="vit" />,
  <PreviousDay key="previous" />,
  <QuoteIconV2 key="quote" />,
  <IkigaiIcon key="ikigai" />,
  <YearIcon key="year" />,
  <WeekIcon key="week" />,
  <DiaryIcon key="diary" />,
  <InsightsIcon key="insights" />,
  <VitIcon key="vit" />,
  <PreviousDay key="previous" />,
  <QuoteIconV2 key="quote" />,
  <IkigaiIcon key="ikigai" />,
  <YearIcon key="year" />,
  <WeekIcon key="week" />,
  <DiaryIcon key="diary" />,
  <InsightsIcon key="insights" />,
  <VitIcon key="vit" />,
  <PreviousDay key="previous" />,
  <QuoteIconV2 key="quote" />,
  <IkigaiIcon key="ikigai" />,
];

const Ticker = () => {
  const tickerRef = useRef(null);

  useEffect(() => {
    const ticker = tickerRef.current;
    let animationFrame;
    let speed = 0.5; // Adjust speed if needed

    const move = () => {
      if (ticker) {
        ticker.scrollLeft += speed;
        if (ticker.scrollLeft >= ticker.scrollWidth / 2) {
          ticker.scrollLeft = 0;
        }
      }
      animationFrame = requestAnimationFrame(move);
    };

    animationFrame = requestAnimationFrame(move);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="ticker-container" ref={tickerRef}>
      <div className="ticker-content">{[...icons, ...icons]}</div>
    </div>
  );
};

export default Ticker;
