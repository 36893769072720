// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, redirect } from "react-router-dom";

//Actions
import { updateProfile } from "../../../actions/dayActions";
import {
  createQuizAction,
  listQuizes,
  updateQuizAction,
} from "../../../actions/quizActions";

//External Packages
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCaretLeft,
  faSquareCaretRight,
  faSquareCheck,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

//Internal Components
import Header from "../../../layouts/Header/Header";
import { TickIcon } from "../../../components/Icons/Icon";
import TileLoading from "../../../components/Loading/TileLoading";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import TestFooter from "../../../layouts/Footer/TestFooter";

//Utilities
import generateSecret from "../../../utils/generateSecret";

//Styling
import "./quizintro.css";

export default function QuizScreen(history) {
  const [titleMain, setTitleMain] = useState(true);
  const [titleDiv, setTitleDiv] = useState(true);
  const [kickOff, setKickOff] = useState(false);
  const [sceneSet, setSceneSet] = useState(false);
  const [threeParts, setThreeParts] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [chapterOneScore, setChapterOneScore] = useState(1);
  const [chapterTwoScore, setChapterTwoScore] = useState(1);
  const [chapterThreeScore, setChapterThreeScore] = useState(1);
  const [chapterFourScore, setChapterFourScore] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate);
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  const quizCreate = useSelector((state) => state.quizCreate); //
  const { loading, error, quiz } = quizCreate;

  console.log(quizes);

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const submitHandler = (e) => {
    // e.preventDefault();

    const key = generateSecret().toString();

    dispatch(
      createQuizAction(
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          setLinkId((linkId) => response._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
      padding: "24px 12px",
    },
  };

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    loadingTimeout();

    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScore(quizes[0].chapterOneScore);
        setChapterTwoScore(quizes[0].chapterTwoScore);
        setChapterThreeScore(quizes[0].chapterThreeScore);
        setChapterFourScore(quizes[0].chapterFourScore);
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const [yourDataHover, setYourDataHover] = useState(false);

  const handleHoverIn_yourData = () => {
    setYourDataHover((yourDataHover) => true);
  };
  const handleHoverOut_yourData = () => {
    setYourDataHover((yourDataHover) => false);
  };

  const [yourMindsetHover, setYourMindsetHover] = useState(false);

  const handleHoverIn_yourMindset = () => {
    setYourMindsetHover((yourMindsetHover) => true);
  };
  const handleHoverOut_yourMindset = () => {
    setYourMindsetHover((yourMindsetHover) => false);
  };

  const [yourDebriefHover, setYourDebriefHover] = useState(false);

  const handleHoverIn_yourDebrief = () => {
    setYourDebriefHover((yourDebriefHover) => true);
  };
  const handleHoverOut_yourDebrief = () => {
    setYourDebriefHover((yourDebriefHover) => false);
  };

  const [yourQualityHover, setYourQualityHover] = useState(false);

  const handleHoverIn_yourQuality = () => {
    setYourQualityHover((yourQualityHover) => true);
  };
  const handleHoverOut_yourQuality = () => {
    setYourQualityHover((yourQualityHover) => false);
  };

  const [fillWidthOne, setFillWidthOne] = useState();
  const [fillWidthTwo, setFillWidthTwo] = useState();
  const [fillWidthThree, setFillWidthThree] = useState();
  const [fillWidthFour, setFillWidthFour] = useState();

  const animationTimeout = () =>
    setTimeout(() => {
      if (Array.isArray(quizes)) {
        if (quizes.length === 0) {
          setFillWidthOne(0);
          setFillWidthTwo(0);
          setFillWidthThree(0);
          setFillWidthFour(0);
        } else if (quizes.length === 1) {
          setFillWidthOne(quizes[0].chapterOneScore);
          setFillWidthTwo(quizes[0].chapterTwoScore);
          setFillWidthThree(quizes[0].chapterThreeScore);
          setFillWidthFour(quizes[0].chapterFourScore);
        }
      } else {
        setHasQuiz((hasQuiz) => true);
      }
    }, 500);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const [imageLoading, setImageLoading] = useState();
  const handleImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Art of Looking</title>
      </Helmet>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <section
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        className="quizPage"
      >
        <motion.main
          className="quizIntroMain"
          animate={titleMain ? "show" : "hide"}
          variants={showHideState}
          onClick={() => {
            setTitleMain((titleMain) => false);
            setTitleDiv((titleDiv) => false);
            setKickOff((kickOff) => true);
          }}
        >
          {pageLoading && imageLoading ? (
            <div
              style={{
                height: "80vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TileLoading />
            </div>
          ) : (
            <motion.div
              id="titleDivMain"
              animate={titleDiv ? "show" : "hide"}
              variants={showHideState}
              onClick={(e) => {
                if (hasQuiz) {
                  setTitleDiv((titleDiv) => false);
                  setKickOff((kickOff) => true);
                } else {
                  setTitleDiv((titleDiv) => false);
                  setKickOff((kickOff) => true);
                  submitHandler();
                }
              }}
            >
              <motion.img
                className="title__image"
                src="https://cdn.sanity.io/images/ou6d2gec/production/7415f3608333d46689b09be5191e304462189122-8000x4500.png"
                onLoad={handleImageLoad}
                width="100%"
                alt="Art of Looking Cover"
              />
              <span className="clickAnywhere">Click to get Started</span>
            </motion.div>
          )}
        </motion.main>

        <motion.div
          animate={kickOff ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h3 className="quizHeaderWhite">
                The Start of a Great Adventure
              </h3>
            </div>
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/dcc77f20254b5d3e3b9b2293b4ab24490c269425-5000x3250.png"
                alt="the journey ahead"
              ></img>
            </div>

            <p>Our brains are brilliant.</p>
            <p>
              Beneath any perception we can have, our brain is perpetually
              probing what is happening, what has happened to us and what may
              happen to us. This level of processing is so staggering that,
              despite all the advancements of the past few years, it is still
              wildly more sophisticated and powerful than even the most advanced
              A.I. systems.
            </p>
            <p>
              However, for all of this power and potential, there are two things
              that our brains aren't very good at;{" "}
              <strong>self-awareness</strong> and{" "}
              <strong>self-assurance</strong>. As it turns out, the struggle we
              have with these vital skills is a case of our brilliant brains
              being a bit too clever for their own good.
            </p>
            <hr></hr>
            <p>
              Above all else, our brains care about one thing: our continued
              survival.
            </p>
            <p>
              Whether in desperate times or our daily lives, this is often a
              fabulous instinct and it is a highly developed trait of our
              species that we may not be here without. At the pits of our
              despair, in moments of extreme danger or simply when getting ready
              for work, instead of giving in or losing hope, our brains will
              concoct stories, make plans, draw on biases and or increase our
              alertness in order to navigate the potentially tricky situation
              ahead. In such moments, however, the brain doesn't care how
              sustainable its directions are, how true the story it has adopted
              really is or how damaging its actions turn out to be. It only
              cares about keeping you going.
            </p>
            <p>
              Our brain does this because, in simplistic terms, it was
              programmed at a time where life was very different to today.
              Whilst 'modern' life still contains unfathomable dangers for too
              many people all over the world, the average experience of life
              today is safer and longer than any other time in human history;
              and a huge amount of this progress has come, in evolutionary
              terms, in a very, very short period of time. This sudden shift in
              circumstances means our brains don't instinctively orient each of
              us to the needs of today, despite having the potential to do so.
              Today, we can afford to prioritise and push more certainly for
              joy, growth and connection, than simply be blinkered by the near
              constant and necessary search for survival. But, by default, your
              brain won't recognise this.
            </p>
            <p>
              To ensure our brains can help with life as we know it today and,
              in doing so, overcome some of its helpful but ancient instincts,
              it needs a bit of help!
            </p>
            <hr className="dividingLine" />
            <p>
              NoWastedDays.io is designed to provide that help and as you may
              have learned in the tutorial, the Art of Looking is a unique
              adventure designed to help you become an even better analyser of
              your own data. You will follow along with Joey as they explore the
              world of data on No Wasted Days and beyond!
            </p>
            <p>
              Ready to get started? Lets take a look at the journey that lays
              ahead.
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/dcc77f20254b5d3e3b9b2293b4ab24490c269425-5000x3250.png"
              alt="Greek Learnings"
              loading="lazy"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => true);
                setThreeParts((threeParts) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => false);
                setThreeParts((threeParts) => true);
                animationTimeout();
                return () => clearTimeout(animationTimeout);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={threeParts ? "show" : "hide"}
          variants={showHideFlexCol}
          className="quizDiv"
        >
          <section className="newMenu">
            <div
              className="columnMenu one"
              onMouseEnter={handleHoverIn_yourData}
              onMouseLeave={handleHoverOut_yourData}
            >
              <div>
                <h3>Your Data 101</h3>
                <span>
                  In this section are all the essentials: what are you looking
                  at, which insights make the biggest difference and what I have
                  learned from over 800 NoWastedDays entries.
                </span>

                <div id="detailsDiv">
                  <a href={`/insight/quiz/${linkId}/feeling`}>
                    {fillWidthOne > 0 ? (
                      fillWidthOne < 100 ? (
                        <a href={`/insight/quiz/${linkId}/feeling`}>
                          <FontAwesomeIcon icon={faPlay} size="2x" />
                        </a>
                      ) : (
                        <FontAwesomeIcon icon={faSquareCheck} size="2x" />
                      )
                    ) : (
                      <a href={`/insight/quiz/${linkId}/feeling`}>
                        <FontAwesomeIcon icon={faPlay} size="2x" />
                      </a>
                    )}
                  </a>

                  <div>
                    <div className="bar-container">
                      <div
                        id="fillBarOne"
                        className="filling-bar"
                        style={{ width: `${fillWidthOne}%` }}
                      ></div>
                    </div>
                    <label for="fillBarOne">% of Section Completed</label>
                  </div>
                </div>
              </div>
              {vw > 1200 && (
                <div>
                  <a href={`/insight/quiz/${linkId}/feeling`}>
                    <img
                      src="https://cdn.sanity.io/images/ou6d2gec/production/c3bdc6191faaecfbbeed054e9df5e8396decfe0b-5000x3250.png"
                      width="90%"
                      alt="Feeling Score Context"
                    />
                  </a>
                </div>
              )}
            </div>

            <div
              className="columnMenu two"
              onMouseEnter={handleHoverIn_yourMindset}
              onMouseLeave={handleHoverOut_yourMindset}
            >
              <div>
                <h3>Making a Mindset</h3>
                <span>
                  Creating a great head space for looking at your insights
                  matters just as much as any data expertise. On this part of
                  the journey, you will find the pillars of a perfect, practical
                  perspective on your own data.
                </span>

                <div id="detailsDiv">
                  <a href={`/insight/quiz/${linkId}/mindset`}>
                    {fillWidthTwo > 0 ? (
                      fillWidthTwo < 100 ? (
                        <a href={`/insight/quiz/${linkId}/mindset`}>
                          <FontAwesomeIcon icon={faPlay} size="2x" />
                        </a>
                      ) : (
                        <FontAwesomeIcon icon={faSquareCheck} size="2x" />
                      )
                    ) : (
                      <a href={`/insight/quiz/${linkId}/mindset`}>
                        <FontAwesomeIcon icon={faPlay} size="2x" />
                      </a>
                    )}
                  </a>
                  <div>
                    <div className="bar-container">
                      <div
                        id="fillBarTwo"
                        className="filling-bar"
                        style={{ width: `${fillWidthTwo}%` }}
                      ></div>
                    </div>
                    <label htmlFor="fillBartwo">% of Section Completed</label>
                  </div>
                </div>
              </div>
              {vw > 1200 && (
                <div>
                  <a href={`/insight/quiz/${linkId}/mindset`}>
                    <img
                      src="https://cdn.sanity.io/images/ou6d2gec/production/db00c8462de1ce23fb58d5553e2bd2cc34dde0c4-5000x3250.png"
                      width="90%"
                      alt="Mindset Frame"
                      loading="lazy"
                    />
                  </a>
                </div>
              )}
            </div>
            <div
              className="columnMenu three"
              onMouseEnter={handleHoverIn_yourDebrief}
              onMouseLeave={handleHoverOut_yourDebrief}
            >
              <div>
                <h3>Owning an Outcome</h3>
                <span>
                  All of our lives are full of outcomes. This leg of the journey
                  focuses on how you can take any one of them and squeeze the
                  emotional and practical value from it and how this can
                  transform your self-awareness and self-assurance.
                </span>

                <div id="detailsDiv">
                  <a href={`/insight/quiz/${linkId}/debrief`}>
                    {fillWidthThree > 0 ? (
                      fillWidthThree < 100 ? (
                        <a href={`/insight/quiz/${linkId}/debrief`}>
                          <FontAwesomeIcon icon={faPlay} size="2x" />
                        </a>
                      ) : (
                        <FontAwesomeIcon icon={faSquareCheck} size="2x" />
                      )
                    ) : (
                      <a href={`/insight/quiz/${linkId}/debrief`}>
                        <FontAwesomeIcon icon={faPlay} size="2x" />
                      </a>
                    )}
                  </a>
                  <div>
                    <div className="bar-container">
                      <div
                        id="fillBarThree"
                        className="filling-bar"
                        style={{ width: `${fillWidthThree}%` }}
                      ></div>
                    </div>
                    <label htmlFor="fillBarThree">% of Section Completed</label>
                  </div>
                </div>
              </div>
              {vw > 1200 && (
                <div>
                  <a href={`/insight/quiz/${linkId}/debrief`}>
                    <img
                      src="https://cdn.sanity.io/images/ou6d2gec/production/304e962155b2c805949e76d5457c3361726ab33d-5000x3250.png"
                      width="90%"
                      alt="Debrief Frame"
                    />
                  </a>
                </div>
              )}
            </div>
            <div
              className="columnMenu four"
              onMouseEnter={handleHoverIn_yourQuality}
              onMouseLeave={handleHoverOut_yourQuality}
            >
              <div>
                <h3>Growing a Dataset</h3>
                <span>
                  Just as the care and attention of a gardener helps create
                  healthy nutritious food that is good for the body, each day
                  you can tend to your own data in a way that maximises its
                  value to you in the future.
                  {/* In this final leg, we will explore the 3 details that will create a delicious set of data. */}
                </span>

                <div id="detailsDiv">
                  <a href={`/insight/quiz/${linkId}/representative`}>
                    {fillWidthFour > 0 ? (
                      fillWidthFour < 100 ? (
                        <a href={`/insight/quiz/${linkId}/representative`}>
                          <FontAwesomeIcon icon={faPlay} size="2x" />
                        </a>
                      ) : (
                        <FontAwesomeIcon icon={faSquareCheck} size="2x" />
                      )
                    ) : (
                      <a href={`/insight/quiz/${linkId}/representative`}>
                        <FontAwesomeIcon icon={faPlay} size="2x" />
                      </a>
                    )}
                  </a>
                  <div>
                    <div className="bar-container">
                      <div
                        id="fillBarFour"
                        className="filling-bar"
                        style={{ width: `${fillWidthFour}%` }}
                      ></div>
                    </div>
                    <label htmlFor="fillBarFour">% of Section Completed</label>
                  </div>
                </div>
              </div>
              {vw > 1200 && (
                <div>
                  <a href={`/insight/quiz/${linkId}/representative`}>
                    <img
                      src="https://cdn.sanity.io/images/ou6d2gec/production/e315332779538791c4bb200042cb9021c6daa22d-5000x3250.png"
                      width="90%"
                      alt="Representative Section"
                      loading="lazy"
                    />
                  </a>
                </div>
              )}
            </div>
          </section>

          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setThreeParts((threeParts) => false);
                setKickOff((kickOff) => true);
              }}
            />

            {vw < 500 ? (
              <span style={{ fontSize: "8px", textAlign: "center" }}>
                You can start wherever you like. The bars track your progress in
                each section.
              </span>
            ) : (
              <span style={{ fontSize: "1em" }}>
                You can start wherever you like by clicking on the images. The
                bars track your progress in each section.
              </span>
            )}

            <a href={`/insight/quiz/${linkId}/feeling`}>
              <FontAwesomeIcon
                className="modernOnlineExperienceButton"
                icon={faSquareCaretRight}
                size="3x"
              />
            </a>
          </div>
        </motion.div>
      </section>
      <TestFooter />
    </>
  );
}
