//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

//Internal Components
import {
  CreateDayIconV2,
  ValuesIcon,
  VitIcon,
  VisionIcon,
  IkigaiIcon,
  QuoteIconV2,
  HideOverlayIcon,
  QuestionIcon,
  SaveIcon,
  PreviousDay,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  EightIcon,
  NineIcon,
  TenIcon,
  IconWrapper,
} from "../../components/Icons/Icon";

//CSS Style
import "./dock.css";

export default function FullDock({
  today,
  previousDay,
  objectives,
  handleMouseState,
  handleColumnChange,
  handleSubmit,
  load,
  yearList,
  meyear,
}) {
  // Hooks
  const navigate = useNavigate();

  // State
  const [isVisible, setIsVisible] = useState(true);
  const [colorState, setColorState] = useState({
    one: "cls-1-invert",
    two: "cls-2-invert",
  });
  const [viewWidth, setViewWidth] = useState(
    Math.max(document.documentElement.clientWidth)
  );
  const [saveTooltip, setSaveTooltip] = useState("");

  const handleTooltipChange = () => {
    setSaveTooltip("Saving...");
    setTimeout(() => {
      setSaveTooltip("Saved");
    }, 1200);
  };
  const [createDayState, setCreateDayState] = useState({
    active: true,
    text: "",
  });

  const [previousDayState, setPreviousDayState] = useState({
    active: true,
    text: "",
  });

  const [liveObjectives, setLiveObjectives] = useState([]);

  let numIconObj = {
    "obj-one": OneIcon,
    "obj-two": TwoIcon,
    "obj-three": ThreeIcon,
    "obj-four": FourIcon,
    "obj-five": FiveIcon,
    "obj-six": SixIcon,
    "obj-seven": SevenIcon,
    "obj-eight": EightIcon,
    "obj-nine": NineIcon,
    "obj-ten": TenIcon,
  };

  const filteredArray = Object.entries(objectives)
    .filter(([key, obj]) => obj.title.trim() !== "")
    .map(([key, obj]) => ({ key, icon: numIconObj[key], ...obj }));

  const createDayHandler = () => {
    if (today && !today.endScore) {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "Add your final score to move onto a new day.";
        return shallow;
      });
    } else {
      setCreateDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Ready to start a new day?";
        return shallow;
      });
    }
  };

  useEffect(() => {
    createDayHandler();
  }, [today]);

  const previousDayHandler = () => {
    if (!yearList) {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = false;
        shallow.text = "You are yet to log multiple days.";
        return shallow;
      });
    } else {
      setPreviousDayState((prevState) => {
        let shallow = { ...prevState };
        shallow.active = true;
        shallow.text = "Look back at the previous day.";
        return shallow;
      });
    }
  };

  useEffect(() => {
    previousDayHandler();
  }, [yearList]);

  useEffect(() => {
    const handleScroll = () => {
      if (viewWidth > 1024) {
        setIsVisible(window.scrollY <= 0);
      } else {
        setIsVisible(window.scrollY <= 1000);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [viewWidth]);

  return (
    <section className="dock--section">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className={"dock--container"}
      >
        <div className={`jordandock node`} data-tooltip={createDayState.text}>
          {/* Disable if endScore is empty or if moment is missing a momentScore */}
          {createDayState.active ? (
            <a href="/today">
              <CreateDayIconV2 />
            </a>
          ) : (
            <CreateDayIconV2 opacity="0.5" />
          )}
        </div>
        <div
          // Ensure this node is disabled if no previous day exists
          className={`jordandock node`}
          data-tooltip={previousDayState.text}
        >
          {previousDayState.active ? (
            <a href={previousDay}>
              <PreviousDay />
            </a>
          ) : (
            <PreviousDay opacity="0.5" />
          )}
        </div>
        {/* <div
          className={`jordandock node`}
          data-tooltip={"Quickly learn more about your journal"}
        >
          <QuestionIcon />
        </div> */}

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node`}
          // Replace with passing year, initialising it as state and then calling the name key

          data-tooltip={
            meyear && meyear.obj && meyear.obj.name && meyear.obj.birthday
              ? "Me"
              : "Add your name and date of birth to your year map to access it in the dock."
          }
          onClick={() => handleMouseState("me")}
        >
          <VitIcon />
        </div>

        <div
          style={{ height: "40px", width: "40px" }}
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.obj && meyear.obj.values
              ? "My Values"
              : "Add your values in your year map to access them in the dock."
          }
          onClick={() => handleMouseState("values")}
        >
          <ValuesIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.obj && meyear.obj.vision
              ? "My Vision"
              : "Add your vision to your year map to access it through the dock."
          }
          onClick={() => handleMouseState("vision")}
        >
          <VisionIcon />
        </div>

        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.obj && meyear.obj.ikigai
              ? "My Ikigai"
              : "Add your ikigai to your year map to access it in the dock"
          }
          onClick={() => handleMouseState("ikigai")}
        >
          <IkigaiIcon />
        </div>
        <div
          className={`jordandock node`}
          data-tooltip={
            meyear && meyear.obj && meyear.obj.quote
              ? "My Favourite Quote"
              : "Add your favourite quote to your year map to access it in the dock."
          }
          onClick={() => handleMouseState("quote")}
        >
          <QuoteIconV2 />
        </div>

        <div className={`jordandockwide node`} data-tooltip="Hide Overlay">
          <HideOverlayIcon />
        </div>

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        {filteredArray.map((objective, index) => {
          const IconComponent = objective.icon; // Now a function reference

          return (
            <div
              key={index}
              className="jordandock node"
              data-tooltip={
                objectives[objective.key]?.title
                  ? objectives[objective.key].title.slice(0, 20) + "..."
                  : "Add an objective into your week planner to access it here."
              }
              data-name={objective.key}
              onClick={(e) => handleColumnChange(e)}
            >
              {IconComponent && (
                <IconComponent opacity={objectives[objective.key]?.score} />
              )}
            </div>
          );
        })}

        <div className="dock separator">
          <FontAwesomeIcon
            id="separatorIcon"
            className="separator"
            icon={faGripLinesVertical}
          />
        </div>

        <div
          className={`jordandock node_save ${load}`}
          data-tooltip={load === "success" ? saveTooltip : "Click to Save"}
          onClick={(e) => {
            handleSubmit(e);
            handleTooltipChange();
          }}
        >
          <SaveIcon load={load} />
        </div>
      </motion.div>
    </section>
  );
}
