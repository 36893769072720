// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

// Internal Components
import Header from "../../layouts/Header/Header";
import TestFooter from "../../layouts/Footer/TestFooter";

// External Packages
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

// Email
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

// Styling
import "./contact.css";

export default function Contact({ history, params }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [subjectLine, setSubjectLine] = useState("");
  const [body, setBody] = useState("");
  const [paycPicker, setPaycPicker] = useState("");

  const { type } = useParams();

  const navigate = useNavigate();
  const form = useRef();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rt1yckn",
        "template_4oxlqrh",
        form.current,
        "1YKVv6BHraqDPites"
      )
      .then(
        (result) => {
          e.target.reset();
        },
        (error) => {}
      );
    navigate("/");
  };

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <main className="contactPage">
        <form
          id="contactForm"
          className="contact-form"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="container send">
            <div className="row">
              <label
                className="score label"
                style={{ color: "#004a22" }}
                htmlFor="contactName"
              >
                Name:
              </label>
              <input
                id="contactName"
                className="formInput register"
                value={name}
                name="from_name"
                placeholder="Enter your name here"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="row">
              <label
                className="score label"
                style={{ color: "#004a22" }}
                htmlFor="email"
              >
                Email
              </label>
              <input
                id="email"
                className="formInput register"
                type="text"
                value={email}
                name="from_email"
                placeholder="Enter your email address here"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="row">
              <label
                className="score label"
                style={{ color: "#004a22" }}
                htmlFor="contactEnquirySelect"
              >
                Enquiry Type
              </label>
              <select
                id="contactEnquirySelect"
                className="enquirySelect"
                form="contactForm"
                name="enquiry_type"
              >
                {type === "bug_found" ? (
                  <option value="bug_found" selected>
                    Report a Bug
                  </option>
                ) : (
                  <option value="bug_found">Report a Bug</option>
                )}
                <option value="Question">Ask a Question</option>
                <option value="Suggestion">Recommend a New Feature</option>
                <option value="Suggestion">Volunteer Your Skills</option>
                <option value="Complaint">Leave a Complaint</option>
                <option value="Testimony">NWDs Challenge Testimony</option>
                <option value="data_access">Data Access Request</option>
                <option value="account_delete">Account Deletion Request</option>
                <option value="Other">Something Else</option>
              </select>
            </div>

            <div className="row">
              <label
                className="score label"
                style={{ color: "#004a22" }}
                htmlFor="messageArea"
              >
                Message
              </label>
              <textarea
                id="messageArea"
                type="password"
                value={body}
                name="message"
                rows="8"
                placeholder="Message"
                onChange={(e) => setBody(e.target.value)}
              />
            </div>

            <button className="registerButton" type="submit" value="Send">
              Send Message
            </button>
          </div>
        </form>
      </main>

      <TestFooter />
    </>
  );
}
