//React & Redux
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//Internal Compoments
import TestFooter from "../../layouts/Footer/TestFooter";
import BlogPostComponent from "../../components/Blog/BlogPostComponent";
import Header from "../../layouts/Header/Header";
import PageContainer from "../../layouts/PageContainer";

// External Components
// Sanity Client
import { client } from "../../client";

//CSS
import "./about.css";

function AboutScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [pageContent, setPageContent] = useState();
  const [isContentReady, setIsContentReady] = useState(false);
  const [latestContent, setLatestContent] = useState();
  const [nextContent, setNextContent] = useState();

  let slug = "about-nowasteddays";

  async function getPosts() {
    const posts = await client.fetch("*[_type == 'post']");
    return posts;
  }

  useEffect(() => {
    const fetchData = async () => {
      let blogPosts = [];
      try {
        const response = await getPosts();

        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (
              response[i].title.startsWith("LP_") ||
              response[i].title.startsWith("TUTORIAL_") ||
              response[i].title.startsWith("AOFL_") ||
              response[i].title.startsWith("PLAN_") ||
              response[i].title.startsWith("PRIME_") ||
              response[i].title.startsWith("PROCESS_") ||
              response[i].title.startsWith("PAGE_") ||
              response[i].title.startsWith("LOGO_")
            ) {
              console.log("not right");
            } else {
              blogPosts.push(response[i]);
            }
          }
        }
        setPageContent(blogPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <PageContainer className="aboutpage">
        <div className="about-us-page">
          <header className="about--header">
            <h1>
              Our mission is to inspire and facilitate a self-aware, self-assure
              world.
            </h1>
          </header>
          <main className="content">
            <section className="two-columns">
              {/* <div className="about--column"> */}
              <h3>
                Being both self-aware and self-assured is essential for living a
                happy, healthy life because these qualities enable you to
                navigate challenges, make informed decisions, and cultivate a
                positive relationship with yourself, your environment and other
                people.
              </h3>
              <h3>
                However, western society is almost perfectly organised to avoid
                helping any one of us nurture these qualities within ourselves.
              </h3>
              <h3>NoWastedDays.io is my reaction to this fact.</h3>
              {/* <p>
                  You'd think that being self-aware would be the easiest thing
                  in the world. After all, who is better placed to understand
                  the workings and makings of your own mind than... you.
                </p>
                <p>
                  In reality, there are few things in life that are more
                  difficult to gain and sustain that high levels of happy,
                  healthy self-awareness. Here are two interesting ways to
                  explore why that is true.
                </p>
                <p>
                  1. Your brain, in fact, is less interested in the impeccable
                  unpackings of your actual mind than you might think. This is
                  because, above all else, it is optimising for your survival
                  (something based on a wide array of learned reactions and
                  needs) and this is far more often reliant on fabricating
                  narratives and relying on hard wired, anxious instincts that
                  are 1000's of years old than a perfect picture of why you do
                  what you do, or the relative risks of your responses to
                  events, in the present day. Whilst useful for surival, this is
                  not useful for being happy and healthy.
                </p>
                <p>
                  2. The potential power and purpose of self-awareness is
                  something that has interested and frustrated thousands of
                  years of human spiritual and philosophical writings and
                  teachings. You span thousands of years and From Lao Tzu, to
                  Saint Augustine, to, they have all written about how and why
                  self-awareness is
                </p>
                <p>
                  With NoWastedDays, I believe I have a recipe for nurturing
                  self-awareness as well as a tool for making you more
                  productive, mindful and less anxious. But before, I get into
                  that, we need to talk about the other important half of the
                  equation - self-assurance.
                </p> */}
              {/* </div> */}
              {/* <div className="about--column"> */}
              {/* <p>
                  Self-assurance. This quality, just like self-awareness, is
                  hard to realised and often misunderstood. Develop a genuine
                  affinity and affection for yourself, regardless of
                  circumstances
                </p>
                <p>
                  Western society is immaculately designed to both curtail our
                  self-assurance as well as make us manically self-obsessed
                  which is a terribly fragile place to be. Marketing paints a
                  picture of product-led life full of all the things we don't
                  have, social media stories confuse cultivated narratives for
                  anyones real life real-lives, algorithmic media rots our brain
                  by exposing us to aggressive context switching that alter our
                  chemical responses to lived experiences,
                </p>
                <p>
                  And that is just scratching the surface. None of this is
                  helpful. It is a load of noise and has been designed
                  vicariously to hinder more than help people.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Why can't content and technology intervene in our lives just
                  to make us happier, just to make us healthier, without being
                  boring or didactic, or detached from its original principles
                  or purpose due to dis-incentives, ?
                </p> */}
              {/* </div> */}
            </section>
            <section className="full-width">
              <header className="about--header">
                <h1>Learn more about how the platform works here.</h1>
              </header>
              {/* <p>
                For me, after over 1000 days of using and improving the tool I
                have built, it is simple - technology needs to provide just
                enough of a structure to scaffold your thinking, without
                prescribing the true nature of the inputs or devolving
                responsibility for the outputs.
              </p>
              <p>
                What I have created, initially entirely in response to my own
                trauma, is a mindfulness journal, daily planner and analytics
                platform merged into one.
              </p>
              <p>
                It provides a way of organising your mind (whether that is
                described by your goals, values, motivations, purpose), and
                where useful empowers you to translate that into the plans for a
                week and then manifest it into the design of a day.
              </p>
              <p>
                Over time, you unlock personal insights - not preachy or over
                extended prescriptions - just a bit of help spotting the signals
                through the noise; opportunities to learn and to look for
                yourself.
              </p>
              <p>
                A little bit of structure, a little bit of replay, a little bit
                of space to think, a little bit of reflection.
              </p> */}
            </section>
          </main>
        </div>

        {/* <div className="about-us-page">
          <header className="about--header">
            <h1>
              Our mission is to inspire and facilitate a self-aware, self-assure
              world.
            </h1>
          </header>
          <main className="content">
            <section className="two-columns"> */}
        {/* <div className="about--column"> */}
        {/* <h3>
                Being both self-aware and self-assured is essential for living a
                happy, healthy life because these qualities enable you to
                navigate challenges, make informed decisions, and cultivate a
                positive relationship with yourself and others.
              </h3>
              <h3>
                However, western society is almost perfectly organised to avoid
                helping any one of us nurture these qualities within ourselves.
              </h3>
              <h3>NoWastedDays.io is my reaction to this fact.</h3> */}
        {/* <p>
                  You'd think that being self-aware would be the easiest thing
                  in the world. After all, who is better placed to understand
                  the workings and makings of your own mind than... you.
                </p>
                <p>
                  In reality, there are few things in life that are more
                  difficult to gain and sustain that high levels of happy,
                  healthy self-awareness. Here are two interesting ways to
                  explore why that is true.
                </p>
                <p>
                  1. Your brain, in fact, is less interested in the impeccable
                  unpackings of your actual mind than you might think. This is
                  because, above all else, it is optimising for your survival
                  (something based on a wide array of learned reactions and
                  needs) and this is far more often reliant on fabricating
                  narratives and relying on hard wired, anxious instincts that
                  are 1000's of years old than a perfect picture of why you do
                  what you do, or the relative risks of your responses to
                  events, in the present day. Whilst useful for surival, this is
                  not useful for being happy and healthy.
                </p>
                <p>
                  2. The potential power and purpose of self-awareness is
                  something that has interested and frustrated thousands of
                  years of human spiritual and philosophical writings and
                  teachings. You span thousands of years and From Lao Tzu, to
                  Saint Augustine, to, they have all written about how and why
                  self-awareness is
                </p>
                <p>
                  With NoWastedDays, I believe I have a recipe for nurturing
                  self-awareness as well as a tool for making you more
                  productive, mindful and less anxious. But before, I get into
                  that, we need to talk about the other important half of the
                  equation - self-assurance.
                </p> */}
        {/* </div> */}
        {/* <div className="about--column"> */}
        {/* <p>
                  Self-assurance. This quality, just like self-awareness, is
                  hard to realised and often misunderstood. Develop a genuine
                  affinity and affection for yourself, regardless of
                  circumstances
                </p>
                <p>
                  Western society is immaculately designed to both curtail our
                  self-assurance as well as make us manically self-obsessed
                  which is a terribly fragile place to be. Marketing paints a
                  picture of product-led life full of all the things we don't
                  have, social media stories confuse cultivated narratives for
                  anyones real life real-lives, algorithmic media rots our brain
                  by exposing us to aggressive context switching that alter our
                  chemical responses to lived experiences,
                </p>
                <p>
                  And that is just scratching the surface. None of this is
                  helpful. It is a load of noise and has been designed
                  vicariously to hinder more than help people.
                </p>
                <p style={{ fontWeight: "bold" }}>
                  Why can't content and technology intervene in our lives just
                  to make us happier, just to make us healthier, without being
                  boring or didactic, or detached from its original principles
                  or purpose due to dis-incentives, ?
                </p> */}
        {/* </div> */}
        {/* </section>
            <section className="full-width">
              <header className="about--header">
                <h1>Learn more about how the platform works here.</h1>
              </header> */}
        {/* <p>
                For me, after over 1000 days of using and improving the tool I
                have built, it is simple - technology needs to provide just
                enough of a structure to scaffold your thinking, without
                prescribing the true nature of the inputs or devolving
                responsibility for the outputs.
              </p>
              <p>
                What I have created, initially entirely in response to my own
                trauma, is a mindfulness journal, daily planner and analytics
                platform merged into one.
              </p>
              <p>
                It provides a way of organising your mind (whether that is
                described by your goals, values, motivations, purpose), and
                where useful empowers you to translate that into the plans for a
                week and then manifest it into the design of a day.
              </p>
              <p>
                Over time, you unlock personal insights - not preachy or over
                extended prescriptions - just a bit of help spotting the signals
                through the noise; opportunities to learn and to look for
                yourself.
              </p>
              <p>
                A little bit of structure, a little bit of replay, a little bit
                of space to think, a little bit of reflection.
              </p> */}
        {/* </section>
          </main>
        </div> */}
      </PageContainer>
      <TestFooter />
    </>
  );
}

export default AboutScreen;
