// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { listDays } from "../../actions/dayActions";
import { listYears } from "../../actions/yearActions";
import { listWeeks } from "../../actions/weekActions";

// Internal Components & Utilities
import Confetti from "../../components/Animations/Confetti";
import quoteObj from "../../components/Quotes/Quotes";
import { MemoryIcon, QuoteIconV2, VitIcon } from "../../components/Icons/Icon";
import { dateFormatter, memorySelector } from "../../utils/UtilityFunctions";

// Styling
import "./pageloading.css";

const TileLoading = (props) => {
  const dispatch = useDispatch();

  const [tileTextState, setTileTextState] = useState("");
  const [quoteAuthor, setQuoteAuthor] = useState("");
  const [coin, setCoin] = useState("");
  const [quote, setQuote] = useState(0);
  const [memory, setMemory] = useState();
  const [celebration, setCelebration] = useState(false);
  const [quoteOrMemory, setQuoteOrMemory] = useState("");

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const yearList = useSelector((state) => state.yearList);
  const { years } = yearList;

  const weekList = useSelector((state) => state.weekList);
  const { weeks } = weekList;

  const dayList = useSelector((state) => state.dayList);
  const { loading, error, days } = dayList;

  useEffect(() => {
    dispatch(listYears());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listWeeks());
  }, [dispatch, userInfo]);

  useEffect(() => {
    dispatch(listDays());
  }, [dispatch, userInfo]);

  const currentWindow = window.location.href;

  const coinFlip = () => {
    return Math.random() < 0.5
      ? setCoin((coin) => "heads")
      : setCoin((coin) => "tails");
  };

  const quoteSelector = () => {
    setQuote((quote) => Math.floor(Math.random() * quoteObj.length));
  };

  useEffect(() => {
    if (days) {
      setMemory(memorySelector(days));
    }
  }, [days, memorySelector]);

  const dayChecker = () => {
    if (days) {
      let totalDays = days.length;

      if (totalDays === 0 && (coin === "heads" || coin === "tails")) {
        setTileTextState((tileTextState) => `Welcome to your NoWastedDays!`);
        setQuoteAuthor(
          (quoteAuthor) => "Start your first day by clicking on the book icon."
        );
      } else if (
        totalDays === 1 &&
        (coin === "heads" || coin === "tails") &&
        currentWindow.match("/today/")
      ) {
        setTileTextState(
          (tileTextState) =>
            `You've started you're first day! Well Done! Have you heard of the #NWDsChallenge? Complete 10 days and share on social to get £10!.`
        );
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
        setQuoteAuthor((quoteAuthor) => "");
      } else if (totalDays === 1 && coin === "tails") {
        setQuoteOrMemory((quoteOrMemory) => "quote");
        setTileTextState((tileTextState) => `"${quoteObj[quote].quote}"`);
        setQuoteAuthor((quoteAuthor) => `${quoteObj[quote].author}`);
      } else if (totalDays === 1 && coin === "heads") {
        setTileTextState(
          (tileTextState) =>
            `Start to build the habit by logging a day tomorrow. `
        );
        setQuoteAuthor((quoteAuthor) => "");
        setQuoteOrMemory((quoteOrMemory) => "");
      } else if (totalDays >= 2 && totalDays <= 9 && coin === "heads") {
        setTileTextState(
          (tileTextState) =>
            `Complete ${10 - totalDays} more days to activate your insights.`
        );
        setQuoteAuthor((quoteAuthor) => "");
      } else if (totalDays >= 2 && totalDays <= 9 && coin === "tails") {
        setTileTextState(
          (tileTextState) =>
            `Only ${
              10 - totalDays
            } more diary days needed activate your first insights.`
        );
        setQuoteAuthor((quoteAuthor) => "");
      } else if (totalDays === 10) {
        setTileTextState(
          (tileTextState) => `Your insights are live! Go check them out.`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays >= 10 && totalDays < 17) {
        if (coin === "heads") {
          let mem = memory && memory.memory;
          let date = memory && memory.date;
          setTileTextState((tileTextState) => mem);
          setQuoteAuthor((quoteAuthor) => date);
          setQuoteOrMemory((quoteOrMemory) => "memory");
        } else {
          setTileTextState((tileTextState) => `"${quoteObj[quote].quote}"`);
          setQuoteAuthor((quoteAuthor) => `${quoteObj[quote].author}`);
          setQuoteOrMemory((quoteOrMemory) => "quote");
        }
      } else if (totalDays === 17) {
        setTileTextState(
          (tileTextState) =>
            `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays > 17 && totalDays % 7 === 0) {
        setTileTextState(
          (tileTextState) =>
            `You've unlocked another week of insight data. Check out your graphs`
        );
        setQuoteAuthor((quoteAuthor) => "");
        setCelebration((celebration) => true);
        setTimeout(() => {
          setCelebration((celebration) => false);
        }, 4000);
      } else if (totalDays > 17 && coin === "heads") {
        setTileTextState((tileTextState) => memory.memory);
        setQuoteAuthor((quoteAuthor) => memory.date);
        setQuoteOrMemory((quoteOrMemory) => "memory");
      } else if (totalDays > 17 && coin === "tails") {
        setTileTextState((tileTextState) => `"${quoteObj[quote].quote}"`);
        setQuoteAuthor((quoteAuthor) => `${quoteObj[quote].author}`);
        setQuoteOrMemory((quoteOrMemory) => "quote");
      }
    }
  };

  useEffect(() => {
    dayChecker();
  }, [days]);

  useEffect(() => {
    coinFlip();
    quoteSelector();
    if (days) {
      setMemory(memorySelector(days));
    }
  }, [days]);

  const renderLoadingBlock = () => {
    if (quoteOrMemory === "quote") {
      return (
        <div
          className="loading__wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="loading__container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h2>{tileTextState}</h2>
            <h5>{quoteAuthor}</h5>
          </div>
          <div className="loading--icon">
            <QuoteIconV2 />
          </div>
        </div>
      );
    } else if (quoteOrMemory === "memory") {
      return (
        <div
          className="loading__wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="loading__container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "start",
            }}
          >
            <h2 style={{ width: "100%" }}>{tileTextState}</h2>
            <div className="memory__div">
              <span>Memory from the Past. </span>{" "}
              <span>{dateFormatter(quoteAuthor)}</span>
            </div>
          </div>
          <div
            className="loading--icon--memory"
            style={{ transform: "translateY(-6px)" }}
          >
            <MemoryIcon />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="loading__wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="loading__container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {celebration ? <Confetti /> : <></>}
            <h2>{tileTextState}</h2>
            <h5>{quoteAuthor}</h5>
          </div>
          <div className="loading--icon">
            <VitIcon />
          </div>
        </div>
      );
    }
  };

  return <div className="tileLoading">{renderLoadingBlock()}</div>;
};

export default TileLoading;
