/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { Draggable } from "react-beautiful-dnd";
import QuoteItem from "./Item/Item";
import { grid } from "./Constants";
import Title from "./Title";
import { motion } from "framer-motion";
import { StrictModeDroppable } from "./Item/Dropable";
import useResponsiveState from "../../Hooks/useResponsiveState";

export const getBackgroundColor = (isdraggingover, isdraggingfrom) => {
  if (isdraggingover) {
    return "rgba(0, 74, 35, 0)";
  }
  if (isdraggingfrom) {
    return "rgba(0, 74, 35, 0)";
  }
  return "#fff";
};

const Wrapper = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.isdraggingover, props.isdraggingfrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 5px;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;
  max-height: ${500}px;
  /*
  not relying on the items for a margin-bottom
  as it will collapse when the list is empty
*/
  padding-bottom: ${grid}px;
`;
const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
`;

/* stylelint-enable */

const InnerQuoteList = function InnerQuoteList(props) {
  try {
    return (
      props.tasks &&
      props.tasks.map((task, index) => (
        <Draggable key={task.id} draggableId={task.id} index={index}>
          {(dragProvided, dragSnapshot) => (
            <QuoteItem
              key={task.id}
              task={task}
              subTaskIndex={index}
              title={props.title}
              overlay={props.overlay}
              handleScoreChange={props.handleScoreChange}
              handleUrgencyChange={props.handleUrgencyChange}
              onDragEndSteps={props.onDragEndSteps}
              addStep={props.addStep}
              toggleOverlay={props.toggleOverlay}
              toggleSubTask={props.toggleSubTask}
              toggleStep={props.toggleStep}
              toggleDisplay={props.toggleDisplay}
              updateDescription={props.updateDescription}
              updateStep={props.updateStep}
              updateNotes={props.updateNotes}
              deleteStep={props.deleteStep}
              deleteSubtask={props.deleteSubtask}
              deleteObjective={props.deleteObjective}
              isdragging={dragSnapshot.isdragging}
              isgroupedover={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))
    );
  } catch (error) {
    console.error("Error in InnerQuoteList: ", error);
  }
};

function InnerList(props) {
  const { tasks, dropProvided, index, title, onDragEndSteps, viewType } = props;

  return (
    <div className="quotelist--container">
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList
          tasks={tasks}
          title={title}
          subTaskIndex={index}
          overlay={props.overlay}
          onDragEndSteps={onDragEndSteps}
          handleScoreChange={props.handleScoreChange}
          handleUrgencyChange={props.handleUrgencyChange}
          addStep={props.addStep}
          toggleSubTask={props.toggleSubTask}
          toggleStep={props.toggleStep}
          toggleOverlay={props.toggleOverlay}
          toggleDisplay={props.toggleDisplay}
          updateDescription={props.updateDescription}
          updateStep={props.updateStep}
          updateNotes={props.updateNotes}
          deleteStep={props.deleteStep}
          deleteSubtask={props.deleteSubtask}
          deleteObjective={props.deleteObjective}
          viewType={viewType}
        />
        {dropProvided.placeholder}
      </DropZone>
    </div>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = "LIST",
    listType,
    style,
    tasks,
    title,
    useClone,
    onDragEndSteps,
    index,
    viewType,
  } = props;

  return (
    <StrictModeDroppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <QuoteItem
                task={tasks[descriptor.source.index]}
                provided={provided}
                isdragging={snapshot.isdragging}
                isClone
                title={title}
                overlay={props.overlay}
                subTaskIndex={index}
                onDragEndSteps={onDragEndSteps}
                addStep={props.addStep}
                handleScoreChange={props.handleScoreChange}
                handleUrgencyChange={props.handleUrgencyChange}
                toggleSubTask={props.toggleSubTask}
                toggleStep={props.toggleStep}
                toggleOverlay={props.toggleOverlay}
                toggleDisplay={props.toggleDisplay}
                updateDescription={props.updateDescription}
                updateNotes={props.updateNotes}
                updateStep={props.updateStep}
                deleteStep={props.deleteStep}
                deleteSubtask={props.deleteSubtask}
                deleteObjective={props.deleteObjective}
                viewType={viewType}
              />
            )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isdraggingover={dropSnapshot.isdraggingover}
          isDropDisabled={isDropDisabled}
          isdraggingfrom={Boolean(dropSnapshot.draggingFromThisWith).toString()}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                tasks={tasks}
                dropProvided={dropProvided}
                subTaskIndex={index}
                title={title}
                overlay={props.overlay}
                handleScoreChange={props.handleScoreChange}
                handleUrgencyChange={props.handleUrgencyChange}
                onDragEndSteps={onDragEndSteps}
                addStep={props.addStep}
                toggleSubTask={props.toggleSubTask}
                toggleStep={props.toggleStep}
                toggleDisplay={props.toggleDisplay}
                toggleOverlay={props.toggleOverlay}
                updateDescription={props.updateDescription}
                updateStep={props.updateStep}
                updateNotes={props.updateNotes}
                deleteStep={props.deleteStep}
                deleteSubtask={props.deleteSubtask}
                deleteObjective={props.deleteObjective}
                viewType={props.viewType}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              tasks={tasks}
              dropProvided={dropProvided}
              subTaskIndex={index}
              title={title}
              overlay={props.overlay}
              handleScoreChange={props.handleScoreChange}
              handleUrgencyChange={props.handleUrgencyChange}
              onDragEndSteps={onDragEndSteps}
              addStep={props.addStep}
              toggleSubTask={props.toggleSubTask}
              toggleStep={props.toggleStep}
              toggleOverlay={props.toggleOverlay}
              toggleDisplay={props.toggleDisplay}
              updateDescription={props.updateDescription}
              updateStep={props.updateStep}
              updateNotes={props.updateNotes}
              deleteStep={props.deleteStep}
              deleteSubtask={props.deleteSubtask}
              deleteObjective={props.deleteObjective}
              viewType={props.viewType}
            />
          )}
        </Wrapper>
      )}
    </StrictModeDroppable>
  );
}
