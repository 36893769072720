// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Internal Components
import Header from "../../layouts/Header/Header";
import TestFooter from "../../layouts/Footer/TestFooter";
import BlogPostComponent from "../../components/Blog/BlogPostComponent";
import { dateFormatter } from "../../utils/UtilityFunctions";
import BlogLoading from "../../components/Loading/BlogLoading";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

// Sanity Client
import { client } from "../../client";

// Styling
import "./blog.css";

function BlogPostTest() {
  const [pageContent, setPageContent] = useState();
  const [latestContent, setLatestContent] = useState();
  const [nextContent, setNextContent] = useState();
  const [markDefs, setMarkDefs] = useState([]);
  const [date, setDate] = useState();
  const [author, setAuthor] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  let { slug } = useParams();

  async function getPosts() {
    const posts = await client.fetch(`
      *[_type == "post"]{
        _id,
        title,
        slug,
        author,
        publishedAt,
        mainImage {
          asset -> {
            _id,
            url
          },
        },
        body[]{
          ...,
          _type == "image" => {
            _key,
            _type,
            asset->{
              _id,
              url
            },
            alt,
            caption
          }
        }
      }
    `);
    return posts;
  }

  async function getAll() {
    const posts = await client.fetch(`*[_type == 'author']`);

    return posts;
  }

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const response = await getAll();

        if (response) {
          setAuthor(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAll();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let page = {};
      let latest = {};
      let blogPosts = [];

      try {
        const response = await getPosts();

        if (response) {
          for (let i = 0; i < response.length; i++) {
            if (
              response[i].title.startsWith("LP_") ||
              response[i].title.startsWith("TUTORIAL_") ||
              response[i].title.startsWith("AOFL_") ||
              response[i].title.startsWith("PLAN_") ||
              response[i].title.startsWith("POSTER_") ||
              response[i].title.startsWith("PRIME_") ||
              response[i].title.startsWith("PROCESS_") ||
              response[i].title.startsWith("PAGE_") ||
              response[i].title.startsWith("LOGO_")
            ) {
              console.log("not right");
            } else {
              blogPosts.push(response[i]);
            }

            page =
              blogPosts && blogPosts.filter((p, i) => p.slug.current === slug);
            setPageContent(page);
            latest = blogPosts.filter((p, i) => i === 0);
            setLatestContent(latest);
          }

          const filteredIndex = blogPosts.findIndex((item) => item === page);

          if (filteredIndex !== -1 && filteredIndex < blogPosts.length - 1) {
            const itemFollowingFiltered = blogPosts[filteredIndex + 1];
            setNextContent(itemFollowingFiltered);
          } else {
            console.log("Item not found or it's the last item in the array");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [pageLoading, setPageLoading] = useState(true);

  const loadingTimeout = () => {
    var randNum = Math.random(1) * 3000 + Math.random(1) * 3000 + 6000;
    setTimeout(() => {
      if (pageContent && pageContent.length === 1) {
        setPageLoading(false);
      }
    }, randNum);
  };

  let returnAuthor = () => {};

  useEffect(() => {
    loadingTimeout();
  });

  const vw = Math.max(document.documentElement.clientWidth);

  const [viewWidth, setViewWidth] = useState(vw);

  return (
    <>
      {userInfo ? <Header variant="light" /> : <Header variant="light" />}
      <main className="blog__main">
        {pageLoading ? (
          <BlogLoading />
        ) : (
          <section className="blog__canvas">
            {pageContent &&
              pageContent.map((post, index) => (
                <section
                  key={post._id}
                  className={
                    viewWidth > 480 ? "blog__content" : "blog__content--mobile"
                  }
                >
                  <h1 className="blog__block--h4">{post.title}</h1>
                  <label className="blog_label">
                    {dateFormatter(post.publishedAt.substring(0, 10))} |{" "}
                    {author
                      .filter((a, i) => post.author_ref === author._id)
                      .map((a, i) => (
                        <label key={i}>{a.name}</label>
                      ))}
                  </label>

                  <BlogPostComponent body={post.body} />
                </section>
              ))}

            {latestContent !== undefined &&
              latestContent.map((element, index) => (
                <a
                  key={element.slug.current}
                  href={`/blog/${element.slug.current}`}
                >
                  <div className="post--latest">
                    <h1>Latest Post:</h1>
                    <h4>{element.title}</h4>
                  </div>
                </a>
              ))}
          </section>
        )}

        <section className="blog__toolbar">
          <a href="/blog">Back to Blog Menu</a>

          <label htmlFor="podcast">Listen to The NoWastedDays Podcast</label>
          <ul id="podcast" className="blog__links">
            <a
              href="https://open.spotify.com/show/712AlXapv6PDU6ZAyCPoyq?si=311efb9c8aa04e9b"
              target="_blank"
            >
              <li>Spotify</li>
            </a>
            <a
              href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
              target="_blank"
            >
              <li>YouTube</li>
            </a>
            <a
              href="https://podcasts.apple.com/gb/podcast/the-nowasteddays-podcast/id1697163760"
              target="_blank"
            >
              <li>Apple Podcasts</li>
            </a>
          </ul>

          <ul className="blog__social">
            <li>
              <a
                href="https://wa.me/?text=Start%20your%20NoWastedDays%20journal%20today%20for%20free%21%20%3A%20https%3A%2F%2Fnowasteddays.io/register"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/nowasteddays/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCMqVstA-okOwsuZK732k4zQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@nowasteddays.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </li>
          </ul>
        </section>
      </main>
      <TestFooter />
    </>
  );
}

export default BlogPostTest;
