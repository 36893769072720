// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Button, Row, Col, Container, Form } from "react-bootstrap";

// External Packages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faEraser,
  faSquareCaretLeft,
  faSquareCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";

// Internal Components
import Header from "../../../layouts/Header/Header";
import TestFooter from "../../../layouts/Footer/TestFooter";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import generateSecret from "../../../utils/generateSecret";
import TileLoading from "../../../components/Loading/TileLoading";

// User Actions
import { updateProfile } from "../../../actions/dayActions";
import { updateQuizAction, listQuizes } from "../../../actions/quizActions";

// Styling
import "./quizrepresentative.css";

export default function QuizRepresentativeScreen(history) {
  const [titleDiv, setTitleDiv] = useState(true);
  const [kickOff, setKickOff] = useState(false);
  const [honesty, setHonesty] = useState(false);
  const [consistency, setConsistency] = useState(false);
  const [specificity, setSpecificity] = useState(false);

  const [chapterOneScore, setChapterOneScore] = useState(100);
  const [chapterTwoScore, setChapterTwoScore] = useState(100);
  const [chapterThreeScore, setChapterThreeScore] = useState(100);
  const [chapterFourScore, setChapterFourScore] = useState(33);

  const [chapterOneScoreLive, setChapterOneScoreLive] = useState();
  const [chapterTwoScoreLive, setChapterTwoScoreLive] = useState();
  const [chapterThreeScoreLive, setChapterThreeScoreLive] = useState();
  const [chapterFourScoreLive, setChapterFourScoreLive] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const quizUpdate = useSelector((state) => state.quizUpdate); //
  const { success: successUpdate } = quizUpdate;

  const quizList = useSelector((state) => state.quizList);
  const { quizes } = quizList;

  useEffect(() => {
    dispatch(listQuizes());
  }, [dispatch, userInfo, history]);

  const updateHandler = (e) => {
    const key = generateSecret().toString();
    // e.preventDefault();

    dispatch(
      updateQuizAction(
        id,
        chapterOneScore,
        chapterTwoScore,
        chapterThreeScore,
        chapterFourScore
      )
    )
      .then((response) => {
        if (response) {
          console.log("all jiggy");
        } else {
          console.log("something still not right");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (
      !chapterOneScore ||
      !chapterTwoScore ||
      !chapterThreeScore ||
      !chapterFourScore
    )
      return;

    // resetHandler();
  };

  const showHideState = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideGrid = {
    hide: {
      display: "none",
    },
    show: {
      display: "grid",
    },
  };

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const showHideFlex = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
    },
  };

  const showHideFlexCol = {
    hide: {
      display: "none",
    },
    show: {
      display: "flex",
      flexDirection: "column",
    },
  };

  const [hasQuiz, setHasQuiz] = useState(false);
  const [linkId, setLinkId] = useState();

  useEffect(() => {
    if (Array.isArray(quizes)) {
      if (quizes.length === 0) {
        setHasQuiz((hasQuiz) => false);
      } else if (quizes.length === 1) {
        setLinkId((linkId) => quizes[0]._id);

        setChapterOneScoreLive(quizes[0].chapterOneScore);

        if (chapterOneScoreLive > chapterOneScore) {
          setChapterOneScore(chapterOneScoreLive);
        }

        setChapterTwoScoreLive(quizes[0].chapterTwoScore);

        if (chapterTwoScoreLive > chapterTwoScore) {
          setChapterTwoScore(chapterTwoScoreLive);
        }

        setChapterThreeScoreLive(quizes[0].chapterThreeScore);

        if (chapterThreeScoreLive > chapterThreeScore) {
          setChapterThreeScore(chapterThreeScoreLive);
        }

        setChapterFourScoreLive(quizes[0].chapterFourScore);

        if (chapterFourScoreLive > chapterFourScore) {
          setChapterFourScore(chapterFourScoreLive);
        }
      }
    } else {
      setHasQuiz((hasQuiz) => true);
    }
  });

  useEffect(() => {
    document.title = "Art of Looking";
  }, []);

  const [pageLoading, setPageLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const loadingTimeout = () => {
    setTimeout(() => {
      setPageLoading(false);
    }, 5000);
  };

  useEffect(() => {
    loadingTimeout();
  });

  return (
    <>
      <Header variant="light" />
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "24px",
        }}
      >
        {pageLoading && imageLoading ? (
          <div
            style={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TileLoading />
          </div>
        ) : (
          <motion.div
            className="quizIntroMain"
            style={{ overflow: "hidden" }}
            animate={titleDiv ? "show" : "hide"}
            variants={showHideState}
            onClick={() => {
              setTitleDiv((titleDiv) => false);
              setKickOff((kickOff) => true);
            }}
          >
            <motion.img
              className="title__image"
              src="https://cdn.sanity.io/images/ou6d2gec/production/c2541e373bf0fb95f184b631355ab94f6bb1cb06-5000x3250.png"
              onLoad={handleImageLoad}
              width="100%"
              alt="Art of Looking Cover"
              loading="lazy"
            />
            <span className="clickAnywhere">Click to get Started</span>
          </motion.div>
        )}

        <motion.div
          animate={kickOff ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Delicious Data</h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/e315332779538791c4bb200042cb9021c6daa22d-5000x3250.png"
                width="90%"
              ></img>
            </div>
            <br></br>
            <p>
              In data analysis, something you have to do a lot of is ensure is
              that the data you are using to solve a problem is representative
              of that problem. You, as the user and writer of your own diary,
              played a huge role in determining how representative and helpful
              your dataset ends up being in improving your own self-awareness.
            </p>
            <p>
              How can we ensure that the information you provide yourself is of
              the most trustworthy, representative and valuable kind. Well, here
              are 3 principles that are going to help us achieve just that.
            </p>

            <p>
              1.
              <strong style={{ fontSize: "1.5em" }}>Honesty</strong>
            </p>
            <p>
              2.
              <strong style={{ fontSize: "1.5em" }}>Specificity</strong>
            </p>
            <p>
              3.
              <strong style={{ fontSize: "1.5em" }}>Consistency</strong>
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/8cd716633bda27d316bfd41adfe122bdd237be76-5000x3250.png"
              alt="Growing a Dataset"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setKickOff((kickOff = false));
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setKickOff((kickOff) => false);
                setHonesty((honesty) => true);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={honesty ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Honesty is the Best Policy</h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/bd22a42d6443167d168af245424d3f853356c4f5-5000x3250.png"
                alt="honesty is the best policy"
              ></img>
            </div>
            <br />
            <p>Trust is the bedrock of a thriving civil society.</p>
            <p>
              People can only act calmly and conscientiously if they know people
              will honour their commitments and speak openly and honestly to
              them, and adhere to the codes - written and unwritten - that
              perpetuate peace and foster co-operation. Similarly, to get the
              efficiencies and opportunities that come with money, you have to
              trust that when you pay someone they will give you something in
              return, and you both have to trust and believe that money is has a
              value.
            </p>
            <p>The same is true with your diary entries.</p>
            <p>
              By laying down your honest thoughts - the ones that you completely
              trust summarise what you felt and tried to do on any particularly
              day - you will be paid back with a far greater level of insight
              down the line.
            </p>
            <p>Thought of another way, NoWastedDays is not Instagram.</p>
            <p>
              Here you are not incentivised or quietly encouraged to paint a
              higlight-reel picture of your life. On NoWastedDays, you are
              simply, and expressly for your own benefit, encouraged to tell it
              exactly how it was on the day in question.
            </p>
            <p>Do this and the quality of your signals will skyrocket!</p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/bd22a42d6443167d168af245424d3f853356c4f5-5000x3250.png"
              alt="Honesty"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setTitleDiv((titleDiv) => true);
                setHonesty((honesty) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setSpecificity((specificity) => true);
                setHonesty((honesty) => false);

                if (chapterFourScore > chapterFourScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterFourScore((chapterFourScore) => 66.6);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={specificity ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Can you be more specific?</h4>
            </div>
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/8cd716633bda27d316bfd41adfe122bdd237be76-5000x3250.png"
                alt="be more specific"
              ></img>
            </div>
            <br />
            <p>
              It is important to single out specificity with some specificity as
              that little bit of extra detail each day, just like honesty, will
              really tally up over time and contribute to a lot more high
              quality signals and potential for insight.
            </p>
            <p>
              <strong>How does it help?</strong>
            </p>
            <p>
              The perfect example of the power of specificity in your
              NoWastedDays is the part of the <strong>diary</strong> where you
              input the memories you want to capture from the day. When I am
              looking through my <strong>insights</strong>, the details in that
              section spark other memories from the day that I hadn't even
              written down. The days where I have been more specific with these
              details in my <strong>diary</strong>, the greater this memory
              spark size and impact is.
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/8cd716633bda27d316bfd41adfe122bdd237be76-5000x3250.png"
              alt="Specificity"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setHonesty((honesty) => true);
                setSpecificity((specificity) => false);
              }}
            />

            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretRight}
              size="3x"
              onClick={() => {
                setSpecificity((specificity) => false);
                setConsistency((consistency) => true);

                if (chapterFourScore > chapterFourScoreLive) {
                  updateHandler();
                }

                setTimeout(() => {
                  setChapterFourScore((chapterFourScore) => 100);
                }, 1000);
              }}
            />
          </div>
        </motion.div>

        <motion.div
          animate={consistency ? "show" : "hide"}
          variants={vw > 1024 ? showHideGrid : showHideFlex}
          className="quizDiv"
        >
          <div className="leftCol silvs">
            <div className="colTitle">
              <h4 className="quizHeaderWhite">Consistency</h4>
            </div>
            <br />
            <div className="mobileImage">
              <img
                src="https://cdn.sanity.io/images/ou6d2gec/production/e315332779538791c4bb200042cb9021c6daa22d-5000x3250.png"
                alt="consistency is king"
              ></img>
            </div>
            <p>
              Doing your NoWastedDays inputs over an extended period is not only
              important because it will help you build lots of data. It is also
              important because it will give you the views of your habits that
              you are particularly interested in.
              <br />
              <br />
              <strong>
                How does my working week look from Monday to Sunday?
              </strong>
              <br />
              <br />
              <strong>How does a month look?</strong>
              <br />
              <br />
              <strong>How does this month differ from last month?</strong>
              <br />
              <br />
              <strong>This quarter from last quarter?</strong>
              <br />
              <br />
              <strong>This year from last year?</strong>
              <br />
              <br />
              <strong>This day this year from the same day last year?</strong>
              <br />
              <br />
              This ability to compare equivalent periods of time will give you
              extraordinary insights, and the only way to build it is daily
              consistency; one day after the other of honest and specific inputs
              over as long a time as you possibly can.
              <br />
              <br />
              That all being true, remember: you can learn from why you didn't
              do a daily entry to as well, so don't fall off the wagon
              completely if you miss a few days. Asking yourself why you missed
              those days can be as revealing as anything from the days you
              actually wrote down.
            </p>
          </div>
          <div className="rightCol silvs">
            <img
              className="silvImg_Desktop"
              src="https://cdn.sanity.io/images/ou6d2gec/production/e315332779538791c4bb200042cb9021c6daa22d-5000x3250.png"
            />
          </div>
          <div className="buttonRow">
            <FontAwesomeIcon
              className="modernOnlineExperienceButton"
              icon={faSquareCaretLeft}
              size="3x"
              onClick={() => {
                setSpecificity((specificity) => true);
                setConsistency((consistency) => false);
              }}
            />
            <a href={`real_quiz`}>
              <FontAwesomeIcon
                className="modernOnlineExperienceButton"
                icon={faSquareCaretRight}
                size="3x"
                onClick={() => {
                  if (chapterFourScore > chapterFourScoreLive) {
                    updateHandler();
                  }
                }}
              />
            </a>
          </div>
        </motion.div>
      </main>
      <TestFooter />
    </>
  );
}
