const faqQuestionData = [
  {
    question: "What is it?",
  },
  {
    question: "How much does it cost?",
  },
  {
    question: "What's the catch? How come it is free?",
  },
  {
    question: "Can anyone else see my data?",
  },
  {
    question: "What makes this better than other journals / apps?",
  },
  {
    question: "Is No Wasted Days only this platform?",
  },
  {
    question: "How long does it take to sign up?",
  },
  {
    question: "What's the story behind No Wasted Days?",
  },
];

const faqAnswerData = [
  {
    answer:
      "It is a free digital journal built for more meaningful years, more focused weeks and happier, healthier days. It is safe space to think about what matters to you, plan your year accordingly and make it really easy for these things to impact your thinking and feeling on a day-to-day basis. In addition, whenever you feel like it, you can look at your personal insights hub or try one of our interactive experiences. ",
  },
  {
    answer: "It is free.",
  },
  {
    answer:
      "There is no catch. Your data will never be shared with anyone. Every text field is encrypted as soon as it gets sent to or from the database. You can delete your account and data at any time. I will look to cover the costs of delivering the app via paid appearances and focused advertising from products or services that share my fundamental mission.",
  },
  {
    answer:
      "No, only you. All text fields in the journal are encrypted using cryptographic algorithms meaning they are extremely safe and impossible to read. Your data is here to serve one person and one person only: you. It will never be sold or leveraged in any other way than to help you learn more about yourself.",
  },
  {
    answer:
      "Two things. One) The combination of simplicity, safety and smartness. It is easy to use, has a lot of smart elements quietly built in and puts safety first by asking how we can protect against negative consequences of interacting with it and allow you complete control over your own data. Two) That it provides just enough structure to scaffold your thinking but leaves the nature of the inputs and outputs entirely to you. I have found this to be immensely important to create a deeply positive change in users lives.",
  },
  {
    answer:
      "I have a podcast (The NoWastedDays Podcast) and social channels (look in the footer) that have the same mission as the app; to inspire and facilitate a more self-aware, self-assure world.",
  },
  {
    answer:
      "If you do the tutorial, about 2-3 minutes. If you don't, about 10 seconds.",
  },
  {
    answer:
      "It was created 5 years ago at a dark time in my life. I had become hugely stressed and really isolated and, in the depths of that difficult moment, I create a little diary format in an excel sheet to help give everyday more structure and purpose. Over time, it evolved - with more data, more mindful elements, more insights - to be this huge organising force in my life, and have a transformative effect on my self-awareness & self-assurance. People kept asking me about this spreadsheet that I was using and I thought, 'This really should be an app that anyone could use!'. Lots of software development learning later, here we are: a living, breathing platform that anyone can use for free to do more of what they love and love more of what they do.",
  },
];

export { faqAnswerData, faqQuestionData };
